import axios from 'axios'
import i18n, { t } from 'i18next'
import firebase from 'firebase/app'
import 'firebase/firestore'

import { nl2br, convertDate } from '../utils/helpers'
import { colorRed, dateFormat } from '../utils/constants'
import { getDate } from '../utils/calendar'

const longDate = 'ddd, DD.MM.YYYY'

export async function fetchDossier(dossierId) {
  let result = {}

  let options = {
    url: `https://jsn.tourbase.tischler-reisen.de/dossiers/${dossierId}/`,
    method: 'get',
    crossDomain: true
  }
  result = await axios(options)

  const dossier = result.data.dossier.Dossier
  const travellers = {}

  dossier.Travellers.Traveller.forEach(t => (travellers[t.TravellerId] = `${t.FirstName} ${t.LastName}`))

  return {
    sections: await getDossierItems(dossier.DossierItems.DossierItem, travellers),
    general: getDossierGeneral(dossier),
    prices: getDossierPrices(dossier),
    employee: await getDossierEmployee(dossier),
    agency: getDossierAgency(dossier),
    infoPages: getDossierInfoPages(dossier.DossierItems.DossierItem),
    headerImage: getDossierHeaderImage(dossier.headerImage)
  }
}

export async function fetchHotelDetails(code) {
  const firestore = firebase.firestore()
  firestore.settings({ timestampsInSnapshots: true })


  const querySnapshot = await firestore
    .collection('hotels')
    .where('lang', '==', i18n.language)
    .where('mergedCode', '==', code)
    .get()

  if (querySnapshot.empty) {
    return {}
  }

  const hotel = querySnapshot.docs[0].data()

  return {
    images: hotel.images,
    description: hotel.description
  }
}

export async function fetchDestinationDetails(code) {
  const firestore = firebase.firestore()
  firestore.settings({ timestampsInSnapshots: true })

  const querySnapshot = await firestore
    .collection('destinations')
    .where('lang', '==', i18n.language)
    .where('code', '==', code)
    .get()

  if (querySnapshot.empty) {
    return {}
  }

  const destination = querySnapshot.docs[0].data()

  return {
    images: destination.images
  }
}

export async function fetchTripDetails(code) {
  const firestore = firebase.firestore()
  firestore.settings({ timestampsInSnapshots: true })

  const querySnapshot = await firestore
    .collection('trips')
    .where('lang', '==', i18n.language)
    .where('mergedCode', '==', code)
    .get()

  if (querySnapshot.empty) {
    return {}
  }

  const trip = querySnapshot.docs[0].data()

  return {
    images: trip.images
  }
}

async function getDossierItems(dossier, travellers) {
  let items = []

  const flights = getFlights(dossier, travellers)
  const transports = getTransports(dossier, travellers)
  const hotels = await getHotels(dossier, travellers)
  const tours = getTours(dossier, travellers)
  const trips = await getTrips(dossier, travellers)
  const miscHotels = await getMiscHotels(dossier, travellers)
  const miscOther = await getMiscOther(dossier, travellers)

  return items.concat(flights, transports, hotels, tours, trips, miscHotels, miscOther).sort((a, b) => a.id - b.id)
}

function getDossierGeneral(dossier) {
  return {
    dossierNumber: dossier.DossierNr,
    title: dossier.Title,
    firstDate: convertDate(dossier.FirstDate),
    lastDate: convertDate(dossier.LastDate),
    customerNumber: dossier.Customers.Customer.CustomerNr,
    travellers: dossier.Travellers.Traveller.map(t => ({ id: t.TravellerId, name: `${t.FirstName} ${t.LastName}` })),
    createDate: convertDate(dossier.CreateDate)
  }
}

function getDossierPrices(dossier) {
  const found = dossier.DossierItems.DossierItem
    .filter(item => item.ItemPrices)
    .map(item => {
      let type = item['@attributes'].Type
      let beginDate = convertDate(item.BeginDate)
      let endDate = convertDate(item.EndDate)
      let normalizedMembers = item.Travellers.Traveller

      return {
        id: item['@attributes'].InvoicePosition,
        title: item[type].Title || item[type].HotelName,
        beginDate,
        endDate,
        members: normalizedMembers.map(member => member['@attributes'].TravellerId),
        prices: getPrices(item.ItemPrices.ItemPrice, normalizedMembers.length)
      }
    })

  const summaryPrices = {
    totalPrice: 0,
    personPrices: dossier.Travellers.Traveller.map(({ TravellerId, FirstName, LastName }) => ({
      id: TravellerId,
      name: `${FirstName} ${LastName}`,
      price: 0
    }))
  }

  found.forEach(({ members, prices: { pricePerUnit, totalPrice } }) => {
    summaryPrices.totalPrice += totalPrice

    members.forEach(id => {
      summaryPrices.personPrices
        .filter(person => person.id === id)
        .forEach(person => (person.price += pricePerUnit))
    })
  })

  const prices = {
    visibility: dossier.price,
    summaryPrices,
    items: found || []
  }

  return prices
}

async function getDossierEmployee(dossier) {
  const isAgency = !!dossier.Customers.Customer.IsAgent
  let employee = {}

  if (isAgency) {
    const customer = dossier.Customers.Customer

    employee = {
      name: customer.LastName,
      gender: 'a',
      phone: `${customer.Phone['@attributes'].AreaCode} ${customer.Phone['@attributes'].Number}`,
      mail: customer.Email,
      address: `${customer.Address1} ${customer.Address2}, ${customer.PostalCode} ${customer.City}`
    }
  } else {
    const employeeCode = dossier.Code1['@attributes'].Code

    const firestore = firebase.firestore()
    firestore.settings({ timestampsInSnapshots: true })

    const querySnapshot = await firestore
      .collection('teams')
      .where('lang', '==', i18n.language)
      .where('code', '==', employeeCode.toUpperCase())
      .get()

    if (querySnapshot.empty) {
      return {}
    }

    const { name, gender, images, phone, mail } = querySnapshot.docs[0].data()
    employee = { name, gender, images, phone, mail }
  }

  return employee
}

function getDossierAgency(dossier) {
  const { customLogo, customColor } = dossier

  return {
    customLogo,
    customColor: customColor || colorRed,
    isAgency: dossier.Customers.Customer.IsAgent
  }
}

function getDossierHeaderImage(headerImage) {
  if (Object.keys(headerImage).length > 0 && headerImage.constructor === Object) {
    return headerImage
  }
  return {}
}

function getDossierInfoPages(dossier) {
  const rawItems = dossier.filter(item => item['@attributes'].Type === 'Text' && item.Text) || []

  const found = rawItems.map(item => (
    {
      id: item['@attributes'].InvoicePosition,
      title: item.Text.Title,
      details: nl2br(item.Text.Detail)
    }
  ))

  return found
}

function getFlights(dossier, travellers) {
  // flights that are in package as an array on single object
  let nestedFlights = []

  // flights from package will also get title from package and first flight will have general info
  dossier
    .filter(
      item => item['@attributes'].Type === 'Package' &&
        item.Package.PackageItems &&
        item.Package.PackageItems.DossierItem.some(item => item['@attributes'].Type === 'Flight')
    )
    .forEach(item => {
      nestedFlights = nestedFlights.concat(
        item.Package.PackageItems.DossierItem
          .filter(itemNested => itemNested['@attributes'].Type === 'Flight')
          .map((itemNested, idx) => ({
            ...itemNested,
            details: idx > 0 ? '' : nl2br(item.Package.Detail),
            // title: item.Package.Title
            title: idx > 0 ?
              `${t('dossier.flight')} ${t('dossier.item.with')} ${itemNested.Flight.Airlinecode['@attributes'].AirlineName}`
              : item.Package.Title
          }))
      )
    })

  // flights that are on top of dossier as flight type object
  const topFlights = dossier.filter(item => item['@attributes'].Type === 'Flight') || []

  const rawItems = [...nestedFlights, ...topFlights]
  let found = []

  rawItems.forEach(({ '@attributes': attributes, Flight: { Airlinecode, Class, Departure, Arrival, SeatString }, BeginDate, Travellers, details, title }) => {
    found.push({
      id: `${attributes.InvoicePosition}`,
      section: t('dossier.flight'),
      icon: 'ta-flight',
      type: 'airlines',

      title: title || Airlinecode['@attributes'].AirlineName,
      details,

      beginDate: convertDate(BeginDate, { outFormat: longDate }),
      dates: convertDate(BeginDate),

      route: `${Departure['@attributes'].nodeValue} - ${Arrival['@attributes'].nodeValue}, ${Departure['@attributes'].Time} / ${Arrival['@attributes'].Time}`,
      info: `${t('dossier.item.with')} ${Airlinecode['@attributes'].AirlineName} (${Airlinecode['@attributes'].nodeValue}) ${t('dossier.item.in')} ${Class['@attributes'].ClassDescription} ${t('dossier.item.class')}`,
      seats: `${SeatString ? t('dossier.item.seats') + SeatString : ''}`,
      travellers: Travellers.Traveller.map(t => travellers[t['@attributes'].TravellerId])
    })
  })

  return found
}

function getTransports(dossier, travellers) {
  const rawItems = dossier.filter(item => (
    item['@attributes'] &&
    item['@attributes'].Type === 'Misc' &&
    (item.ShortDescription1.startsWith('TRA') || item.ShortDescription1.startsWith('CAR'))
  )) || []

  const found = rawItems
    .map(item => {
      let beginDateLong = convertDate(item.BeginDate, { outFormat: longDate })
      let endDateLong = convertDate(item.EndDate, { outFormat: longDate })
      let beginDateShort = convertDate(item.BeginDate)
      let endDateShort = convertDate(item.EndDate)

      return {
        id: `${item['@attributes'].InvoicePosition}`,
        section: item.ShortDescription1.startsWith('TRA') ? t('dossier.transfer') : t('dossier.car'),
        icon: 'ta-car',
        type: 'transports',

        title: item.Misc.Title,
        beginDate: beginDateLong,
        endDate: endDateLong,

        dates: `${beginDateShort}${endDateShort ? ' - ' + endDateShort : ''}`,
        subtitle: `${item.Misc.Title}`,
        details: nl2br(item.Misc.Detail),
        route: item.Misc.Arrival && `${t('dossier.item.from')} ${item.Misc.Arrival['@attributes'].Time || ''} ${item.Misc.Arrival['@attributes'].Description} ${t('dossier.item.to')} ${(item.Misc.Departure && item.Misc.Departure['@attributes'].Time) || ''} ${(item.Misc.Departure && item.Misc.Departure['@attributes'].Description) || ''}`,
        travellers: item.Travellers.Traveller.map(t => travellers[t['@attributes'].TravellerId])
      }
    })
  return found
}

function getMiscHotels(dossier, travellers) {
  const rawItems = dossier.filter(item => (
    item['@attributes'] &&
    item['@attributes'].Type === 'Misc' &&
    item.ShortDescription1.startsWith('HTL')
  )) || []

  const found = rawItems
    .map(item => {
      let beginDateLong = convertDate(item.BeginDate, { outFormat: longDate })
      let endDateLong = convertDate(item.EndDate, { outFormat: longDate })
      let beginDateShort = convertDate(item.BeginDate)
      let endDateShort = convertDate(item.EndDate)

      return {
        id: `${item['@attributes'].InvoicePosition}`,
        section: item.Misc.Title,
        icon: 'ta-hotel',
        type: 'miscHotels',

        title: item.Misc.Title,
        beginDate: beginDateLong,
        endDate: endDateLong,

        dates: `${beginDateShort}${endDateShort ? ' - ' + endDateShort : ''}`,
        subtitle: `${item.Misc.Title}`,
        details: nl2br(item.Misc.Detail),
        travellers: item.Travellers.Traveller.map(t => travellers[t['@attributes'].TravellerId])
      }
    })
  return found
}

function getMiscOther(dossier, travellers) {
  const rawItems = dossier.filter(item => (
    item['@attributes'] &&
    item['@attributes'].Type === 'Misc' &&
    (!item.ShortDescription1.startsWith('EXC') &&
      !item.ShortDescription1.startsWith('TRA') &&
      !item.ShortDescription1.startsWith('CAR') &&
      !item.ShortDescription1.startsWith('HTL'))
  )) || []

  const found = rawItems
    .map(item => ({
      id: `${item['@attributes'].InvoicePosition}`,
      section: item.Misc.Title,
      icon: 'ta-info',
      type: 'miscOther',

      title: item.Misc.Title,
      beginDate: convertDate(item.BeginDate, { outFormat: longDate }),

      dates: convertDate(item.BeginDate),
      subtitle: `${item.Misc.Title}`,
      details: nl2br(item.Misc.Detail),
      travellers: item.Travellers.Traveller.map(t => travellers[t['@attributes'].TravellerId])
    }))
  return found
}

async function getHotels(dossier, travellers) {
  const rawItems = dossier.filter(item => (
    item['@attributes'].Type === 'Hotel'
  )) || []

  const found = []
  const inProgram = new Set()

  for (let item of rawItems) {
    let beginDateLong = convertDate(item.BeginDate, { outFormat: longDate })
    let endDateLong = convertDate(item.EndDate, { outFormat: longDate })
    let beginDateShort = convertDate(item.BeginDate)
    let endDateShort = convertDate(item.EndDate)
    let code = (item.Hotel.Destination || '') + (item.Hotel.HotelCode || '')
    let uniqueName = code + item.Hotel.HotelName
    let details = ''

    if (code && !inProgram.has(uniqueName)) {
      details = await fetchHotelDetails(code)
      inProgram.add(uniqueName)
    }

    found.push({
      id: `${item['@attributes'].InvoicePosition}`,
      section: item.Hotel.HotelName,
      icon: 'ta-hotel',
      type: 'hotels',

      title: item.Hotel.HotelName,
      rating: item.Hotel.Category,
      beginDate: beginDateLong,
      endDate: endDateLong,
      dates: `${beginDateShort} - ${endDateShort}`,
      subtitle: `${item.Hotel.HotelName}` + (item.Hotel.City ? `, ${item.Hotel.City}` : ''),

      overview: {
        room: `${item.Hotel.RoomType}`,
        included: `${item.Hotel.Included}`
      },
      details,

      travellers: item.Travellers.Traveller.map(t => travellers[t['@attributes'].TravellerId])
    })
  }

  return found
}

function getTours(dossier, travellers) {
  const rawItems = dossier.filter(item => (
    item['@attributes'].Type === 'Package' && item.ShortDescription1.startsWith('RTP')
  )) || []

  const found = rawItems.map(item => {
    const beginDateLong = convertDate(item.BeginDate, { outFormat: longDate })
    const endDateLong = convertDate(item.EndDate, { outFormat: longDate })
    const beginDateShort = convertDate(item.BeginDate)
    const endDateShort = convertDate(item.EndDate)

    let overview = item.Package.PackageItems.DossierItem
      .find(item => item['@attributes'].Type === 'Misc')

    let items = []
    let hotels = []

    item.Package.PackageItems.DossierItem
      .filter(item => item['@attributes'].Type === 'Text' || item['@attributes'].Type === 'Hotel')
      .forEach((item, idx) => {
        let beginShort = convertDate(item.BeginDate)
        let endShort = convertDate(item.EndDate)

        if (item.Text && item.BeginDate && !item.Text.TextCode) {
          items.push({
            id: `${item['@attributes'].InvoicePosition}-${idx}`,
            type: 'text',
            start: beginShort,
            end: endShort,
            date: `${beginShort}${endShort ? ' - ' + endShort : ''}`,
            title: `${item.Text.Title}`,
            details: nl2br(item.Text.Detail),
            hotels: []
          })
        } else if (item.Text && item.Text.TextCode) {
          items.push({
            id: `${item.Text.TextCode}${item['@attributes'].InvoicePosition}`,
            type: 'region',
            title: `${item.Text.Title}`,
            details: nl2br(item.Text.Detail),
            code: item.Text.TextCode
          })
        } else if (item.Hotel) {
          hotels.push({
            id: (item.Hotel.Destination || '') + (item.Hotel.HotelCode || '') + item['@attributes'].InvoicePosition,
            type: 'hotel',
            start: beginShort,
            end: endShort,
            date: `${beginShort}${endShort ? ' - ' + endShort : ''}`,
            title: item.Hotel.HotelName,
            rating: item.Hotel.Category || 0,
            room: item.Hotel.RoomType,
            included: item.Hotel.Included,
            city: item.Hotel.City,
            code: (item.Hotel.Destination || '') + (item.Hotel.HotelCode || '')
          })
        }
      })

    hotels.forEach(hotel => {
      items
        .filter(i => i.type === 'text' && i.start && !i.end)
        .forEach(item => {
          const hotelStart = getDate(hotel.start, dateFormat)
          const hotelEnd = getDate(hotel.end, dateFormat)
          const dayStart = getDate(item.start, dateFormat)

          if (dayStart < hotelEnd && dayStart >= hotelStart) {
            item.hotels.push(hotel)
          }
        })
    })

    return {
      id: `${item['@attributes'].InvoicePosition}`,
      section: item.Package.Title,
      icon: 'ta-roundtrip',
      type: 'tours',

      title: item.Package.Title,
      beginDate: beginDateLong,
      endDate: endDateLong,
      dates: `${beginDateShort}${endDateShort ? ' - ' + endDateShort : ''}`,
      subtitle: `${item.Package.Title}`,

      overview: {
        title: overview.Misc && overview.Misc.Title,
        details: nl2br(overview.Misc && overview.Misc.Detail)
      },
      items,
      travellers: item.Travellers.Traveller.map(t => travellers[t['@attributes'].TravellerId])
    }
  })

  return found
}

async function getTrips(dossier, travellers) {
  const rawItems = dossier.filter(item => (
    item['@attributes'].Type === 'Misc' && item.ShortDescription1.startsWith('EXC')
  )) || []

  const found = []

  for (let item of rawItems) {
    let code = (item.Misc.Destination || '') + (item.Misc.MiscCode || '')
    let description = ''

    if (code) {
      description = await fetchTripDetails(code)
    }

    found.push({
      id: `${item['@attributes'].InvoicePosition}`,
      section: item.Misc.Title,
      icon: 'ta-excursion',
      type: 'trips',

      title: item.Misc.Title,
      beginDate: convertDate(item.BeginDate, { outFormat: longDate }),

      dates: convertDate(item.BeginDate),
      subtitle: `${item.Misc.Title}`,

      arrival: {
        time: `${(item.Misc.Arrival && item.Misc.Arrival['@attributes'].Time) || ''}`,
        title: `${(item.Misc.Arrival && item.Misc.Arrival['@attributes'].Description) || ''}`
      },
      departure: {
        time: `${(item.Misc.Departure && item.Misc.Departure['@attributes'].Time) || ''}`,
        title: `${(item.Misc.Departure && item.Misc.Departure['@attributes'].Description) || ''}`
      },

      details: nl2br(item.Misc.Detail),
      description,
      travellers: item.Travellers.Traveller.map(t => travellers[t['@attributes'].TravellerId])
    })
  }

  return found
}

function getPrices(itemPrice, unitNumber) {
  const calculatedPrices = itemPrice.reduce((prev, curr) => {
    return {
      totalPrice: (Number(prev.totalPrice) + Number(curr.TotalPrice)),
      subprices: [...prev.subprices, {
        description: curr.PriceDescription,
        members: curr.PriceTravellers.PriceTraveller.map(member => member['@attributes'].TravellerId),
        pricePerUnit: Number(curr.PricePerUnit),
        totalPrice: Number(curr.TotalPrice)
      }]
    }
  }, { totalPrice: 0, subprices: [] })

  calculatedPrices.pricePerUnit = (Number(calculatedPrices.totalPrice) / Number(unitNumber))

  return calculatedPrices
}

import React, { Component } from 'react'
import { Grid, List, Segment, Table, Icon, Accordion, Divider, Header } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'
import EmployeeCard from './EmployeeCard'
import { formatNumberToEuro } from '../../utils/helpers'

class Prices extends Component {
  state = { descId: '', pageId: '', generating: false, print: false }

  toggleDescription = (id) => {
    this.setState(prev => ({ descId: prev.descId === id ? '' : id }))
  }

  togglePage = (id) => {
    this.setState(prev => ({ pageId: prev.pageId === id ? '' : id }))
  }

  handlePdf = ({ print = false }) => {
    const { onPdf } = this.props
    this.setState({ generating: true, print })

    onPdf({ print }).then(() => this.setState({ generating: false, print }))
  }

  render () {
    const { t, general, prices: { items, summaryPrices, visibility }, employee, infoPages, color } = this.props
    const { descId, pageId, generating, print } = this.state

    return (
      <Segment basic vertical>
        <Grid>
          <Grid.Column width={5}>
            <Segment inverted attached='top' style={{ backgroundColor: color }}>
              <Header as='h3' inverted>
                {visibility.priceBox ? t('dossier.prices.header') : t('dossier.prices.header-noPriceBox')}
              </Header>
            </Segment>
            <Segment attached>
              <p style={{ color }}>
                «{general.title}»<br />
                {t('dossier.prices.from')} {general.firstDate} {t('dossier.prices.to')} {general.lastDate}
              </p>
              <p>
                <strong>{t('dossier.general.number')}:</strong> N° {general.dossierNumber}<br />
                <strong>{t('dossier.general.dates')}:</strong> {general.createDate}<br />
                <strong>{t('dossier.general.client')}:</strong> {general.customerNumber}
              </p>
            </Segment>

            <Divider hidden />

            <Segment inverted attached='top' style={{ backgroundColor: color }}>
              <Header as='h3' inverted>{t('dossier.general.travellers')}</Header>
            </Segment>
            <Segment attached>
              <List divided>
                {general.travellers && general.travellers.map(({ id, name }) => (
                  <List.Item key={name}><strong>{name} ({id})</strong></List.Item>
                ))}
              </List>
            </Segment>
          </Grid.Column>
          <Grid.Column width={11}>

            <EmployeeCard employee={employee} color={color} />

            {visibility.priceBox && 
              <Segment basic padded className='white' clearing>
                <Header as='h2' style={{ color }} floated='left'>{t('dossier.prices.price')}</Header>
                <Icon className={`${generating && !print ? 'ta-spinner' : 'ta-pdf'}`} loading={generating && !print} circular style={{ backgroundColor: color, color: '#fff', float: 'right' }} size='large' link onClick={this.handlePdf} />
                <Icon className={`${generating && print ? 'ta-spinner' : 'ta-print'}`} loading={generating && print} circular style={{ backgroundColor: color, color: '#fff', float: 'right' }} size='large' link onClick={() => this.handlePdf({ print: true })} />

                <Divider hidden clearing />

                <Table basic='very' padded selectable>
                  <Table.Header fullWidth>
                    <Table.Row>
                      <Table.HeaderCell>{t('dossier.prices.desc')}</Table.HeaderCell>
                      <Table.HeaderCell>{t('dossier.prices.traveller')}</Table.HeaderCell>
                      {visibility.serviceItems && <Table.HeaderCell textAlign='right'>{t('dossier.prices.total')}</Table.HeaderCell>}
                      <Table.HeaderCell />
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {items &&
                      items.map(({ id, beginDate, endDate, title, members, prices }) => (
                        <React.Fragment key={id}>
                          <Table.Row onClick={() => this.toggleDescription(id)} style={{ cursor: 'pointer' }}>
                            <Table.Cell><span style={{ color }}>{beginDate}{endDate ? ` - ${endDate}` : ''}</span> <strong>{title}</strong></Table.Cell>
                            <Table.Cell>{members.join(', ')}</Table.Cell>
                            {visibility.serviceItems &&
                              <React.Fragment>
                                <Table.Cell singleLine textAlign='right'>{formatNumberToEuro(prices.pricePerUnit)} / {formatNumberToEuro(prices.totalPrice)}</Table.Cell>
                                <Table.Cell collapsing>
                                  <Icon name='dropdown' size='large' style={{ color, transform: `${descId === id ? 'rotate(-180deg)' : 'none'}` }} />
                                </Table.Cell>
                              </React.Fragment>
                            }
                          </Table.Row>
                          {visibility.serviceItems && descId === id && prices.subprices.map(({ description, pricePerUnit, totalPrice, members }, idx) => (
                            <Table.Row key={`${idx}-${id}`}>
                              <Table.Cell>{description}</Table.Cell>
                              <Table.Cell>{members.join(', ')}</Table.Cell>
                              <Table.Cell singleLine textAlign='right'>{formatNumberToEuro(pricePerUnit)} / {formatNumberToEuro(totalPrice)}</Table.Cell>
                              <Table.Cell collapsing>&nbsp;</Table.Cell>
                            </Table.Row>
                          ))}
                        </React.Fragment>
                      ))}
                  </Table.Body>
                </Table>

                {visibility.perPerson && summaryPrices.personPrices.map(({ id, name, price }) => (
                  <Segment inverted clearing attached='top' style={{ backgroundColor: color }} key={id}>
                    <Header as='h3' inverted floated='left'>{name} ({id})</Header>
                    <Header as='h3' inverted floated='right'>EUR {formatNumberToEuro(price)}</Header>
                  </Segment>
                ))}
                {visibility.total &&
                  <Segment color='grey' inverted clearing attached='bottom'>
                    <Header as='h3' inverted floated='left'>{t('dossier.prices.sum')}</Header>
                    <Header as='h3' inverted floated='right'>EUR {formatNumberToEuro(summaryPrices.totalPrice)}</Header>
                  </Segment>
                }
              </Segment>
            }

            <Divider hidden />

            <Segment basic padded className='white' clearing>
              <Header as='h2' style={{ color }} floated='left'>{t('dossier.prices.more-info')}</Header>
              {!visibility.priceBox && <Icon className={`${generating && !print ? 'ta-spinner' : 'ta-pdf'}`} loading={generating && !print} circular style={{ backgroundColor: color, color: '#fff', float: 'right' }} size='large' link onClick={this.handlePdf} />}
              {!visibility.priceBox && <Icon className={`${generating && print ? 'ta-spinner' : 'ta-print'}`} loading={generating && print} circular style={{ backgroundColor: color, color: '#fff', float: 'right' }} size='large' link onClick={() => this.handlePdf({ print: true })} />}
              {!visibility.priceBox && <Divider hidden clearing />}

              {infoPages &&
                <Accordion fluid>
                  {infoPages.map(({ id, title, details }) => (
                    <React.Fragment key={id}>
                      <Accordion.Title index={id} active={pageId === id} onClick={(e, { index }) => this.togglePage(index)}>
                        <Icon name='dropdown' />
                        {title}
                      </Accordion.Title>
                      <Accordion.Content active={pageId === id}>
                        <div dangerouslySetInnerHTML={{ __html: details }} />
                      </Accordion.Content>
                    </React.Fragment>
                  ))}
                </Accordion>
              }
            </Segment>

          </Grid.Column>
        </Grid>
      </Segment>
    )
  }
}

export default withTranslation()(Prices)

import React, { Component } from 'react'
import { Segment, Table, Icon, Accordion, Divider, Header } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'
import { formatNumberToEuro } from '../../utils/helpers'

class PricesMobile extends Component {
  state = { descId: '', pageId: '', generating: false, print: false }

  toggleDescription = (id) => {
    this.setState(prev => ({ descId: prev.descId === id ? '' : id }))
  }

  togglePage = (id) => {
    this.setState(prev => ({ pageId: prev.pageId === id ? '' : id }))
  }

  handlePdf = ({ print = false }) => {
    const { onPdf } = this.props
    this.setState({ generating: true, print })

    onPdf({ print }).then(() => this.setState({ generating: false, print }))
  }

  render() {
    const { t, prices: { items, summaryPrices, visibility }, infoPages, color } = this.props
    const { descId, pageId, generating, print } = this.state

    return (
      <Segment basic vertical>

        {visibility.priceBox &&
          <Segment basic className='white' clearing>
            <Header as='h2' style={{ color }} floated='left'>{t('dossier.prices.price')}</Header>

            <Icon className={`${generating && !print ? 'ta-spinner' : 'ta-pdf'}`} loading={generating && !print} circular style={{ backgroundColor: color, color: '#fff', float: 'right' }} size='large' link onClick={this.handlePdf} />
            <Icon className={`${generating && print ? 'ta-spinner' : 'ta-print'}`} loading={generating && print} circular style={{ backgroundColor: color, color: '#fff', float: 'right' }} size='large' link onClick={() => this.handlePdf({ print: true })} />

            <Divider hidden clearing />

            <Table basic='very' compact='very' selectable stackable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>{t('dossier.prices.desc')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('dossier.prices.traveller')}</Table.HeaderCell>
                  {visibility.serviceItems && <Table.HeaderCell>{t('dossier.prices.total')}</Table.HeaderCell>}
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {items && items
                  .map(({ id, beginDate, endDate, title, members, prices }) => (
                    <React.Fragment key={id}>
                      <Table.Row onClick={() => this.toggleDescription(id)} style={{ cursor: 'pointer' }}>
                        <Table.Cell><span style={{ color }}>{beginDate}{endDate ? ` - ${endDate}` : ''}</span> <strong>{title}</strong></Table.Cell>
                        <Table.Cell>{members.join(', ')}</Table.Cell>
                        {visibility.serviceItems &&
                          <Table.Cell>
                            {prices.pricePerUnit} / {prices.totalPrice}
                            <Icon name='dropdown' size='large' style={{ color, float: 'right', transform: `${descId === id ? 'rotate(-180deg)' : 'none'}` }} />
                          </Table.Cell>
                        }
                      </Table.Row>
                      {visibility.serviceItems && descId === id && prices.subprices.map(({ description, pricePerUnit, totalPrice, members }, idx) => (
                        <Table.Row key={`${idx}-${id}`}>
                          <Table.Cell>{description}</Table.Cell>
                          <Table.Cell>{members.join(', ')}</Table.Cell>
                          <Table.Cell>{pricePerUnit} / {totalPrice}</Table.Cell>
                        </Table.Row>
                      ))}
                    </React.Fragment>
                  ))}
              </Table.Body>
            </Table>


            {visibility.perPerson && summaryPrices.personPrices.map(({ id, name, price }) => (
              <Segment inverted clearing attached='top' style={{ backgroundColor: color }} key={id}>
                <Header as='h3' inverted floated='left'>{name} ({id})</Header>
                <Header as='h3' inverted floated='right'>EUR {formatNumberToEuro(price)}</Header>
              </Segment>
            ))}
            {visibility.total &&
              <Segment color='grey' inverted clearing attached='bottom'>
                <Header as='h3' inverted floated='left'>{t('dossier.prices.sum')}</Header>
                <Header as='h3' inverted floated='right'>EUR {formatNumberToEuro(summaryPrices.totalPrice)}</Header>
              </Segment>
            }
          </Segment>
        }

        <Divider hidden />

        <Segment basic padded className='white' clearing>
          <Header as='h2' style={{ color }} floated='left'>{t('dossier.prices.more-info')}</Header>

          {!visibility.priceBox && <Icon className={`${generating && !print ? 'ta-spinner' : 'ta-pdf'}`} loading={generating && !print} circular style={{ backgroundColor: color, color: '#fff', float: 'right' }} size='large' link onClick={this.handlePdf} />}
          {!visibility.priceBox && <Icon className={`${generating && print ? 'ta-spinner' : 'ta-print'}`} loading={generating && print} circular style={{ backgroundColor: color, color: '#fff', float: 'right' }} size='large' link onClick={() => this.handlePdf({ print: true })} />}
          {!visibility.priceBox && <Divider hidden clearing />}

          {infoPages &&
            <Accordion fluid>
              {infoPages.map(({ id, title, details }) => (
                <React.Fragment key={id}>
                  <Accordion.Title index={id} active={pageId === id} onClick={(e, { index }) => this.togglePage(index)}>
                    <Icon name='dropdown' />
                    {title}
                  </Accordion.Title>
                  <Accordion.Content active={pageId === id}>
                    <div dangerouslySetInnerHTML={{ __html: details }} />
                  </Accordion.Content>
                </React.Fragment>
              ))}
            </Accordion>
          }
        </Segment>

      </Segment>
    )
  }
}

export default withTranslation()(PricesMobile)
